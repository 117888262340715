<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => createElement('span', 'close'),
  },
  OpenIndicator: {
    render: (createElement) => createElement('span', 'expand_more'),
  },
});

export default {
  extends: vSelect,
};
</script>

<style scoped lang="scss">
.v-select {
  font-size: 14px;
  font-weight: 400;
  min-width: 100px;

  .vs__dropdown-toggle {
    min-width: 100% !important;
    max-width: 100% !important;
    border: 1px solid rgba(120, 141, 180, 0.3);
  }
}
.vs__selected-options {
  min-height: 31px;
}
</style>
