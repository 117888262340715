<template>
  <div class="content">
    <PageHeader title="Feriados" />
    <div class="page-content container-fluid">
      <div v-if="loading" class="qt-block-ui"></div>
      <div class="card">
        <div class="card-header">Feriado</div>
        <div class="card-body block-el">
          <div>
            <label class="control-label text-left">Titulo</label>
            <input type="text" class="form-control" v-model="form.name">
          </div>
          <div class="row">
            <div class="col-6 col-lg-3">
              <label class="control-label text-left">Data de início</label>
              <input type="date" class="form-control" v-model="dateBegin">
            </div>
            <div class="col-6 col-lg-3">
              <label class="control-label text-left">Hora de início</label>
              <input type="time" class="form-control" v-model="hourBegin">
            </div>
            <div class="col-6 col-lg-3">
              <label class="control-label text-left">Data de termino</label>
              <input type="date" class="form-control" v-model="dateEnd">
            </div>
            <div class="col-6 col-lg-3">
              <label class="control-label text-left">Hora de termino</label>
              <input type="time" class="form-control" v-model="hourEnd">
            </div>
          </div>
          <div class="py-2">
            <label class="form-label">Departamentos</label>
            <select-input
              label="name"
              v-model="form.departments"
              :reduce="(field) => `${field.id};${field.name}`"
              :options="departments"
              :clearable="true"
              :multiple="noGeneral"
            />
          </div>
          <div class="py-2">
            <label class="form-label">Mensagem que será exibida para os clientes no feriado</label>
            <textarea class="form-control" v-model="form.message"></textarea>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end bg-light">
          <button class="btn btn-primary" @click="save">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Emojis from '@/components/Emojis.vue';
import PageHeader from '@/components/PageHeader.vue';
// import { ToggleButton } from 'vue-js-toggle-button';
import SelectInput from '@/components/SelectInput.vue';
import axios from '@/plugins/axios';

export default {
  name: 'CreateEditHoliday',
  components: {
    // InfiniteLoading,
    // Emojis,
    PageHeader,
    SelectInput,
    // ToggleButton,
  },
  props: ['id'],
  data() {
    return {
      loading: true,
      mainDepartment: null,
      formDepartmentsTimeout: 0,
      departments: [], // departamentos selecionaveis
      dateBegin: null,
      dateEnd: null,
      hourBegin: '00:01',
      hourEnd: '23:59',
      form: {
        // 2007-12-03T10:15:30
        date_begin: null,
        date_end: null,
        departments: [], // departamentos escolhidos
        message: null,
        name: null,
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    noGeneral() {
      const depts = this.form.departments;
      const main = this.mainDepartment;

      if (depts === null || depts.length < 2 || !main) return true;

      const j = depts.length;

      for (let i = 0; i < j; i += 1) {
        if (depts[i] === main) {
          this.updateFormDepartments(depts[i]);
          return false;
        }
      }

      return true;
    },
  },
  mounted() {
    console.log('holidays:mounted', this.id);
    this.fetch();
  },
  destroyed() {
  },
  watch: {
    // 'form.departments': (newVal) => {
    //   console.log('form.departments', this);

    //   if (newVal.length > 1) {
    //     for (let i = newVal.length - 1; i >= 0; i -= 1) {
    //       const item = newVal[i];

    //       if (item.indexOf(';Geral') !== -1) {
    //         // this.form.departments = ;
    //         return [item];
    //       }
    //     }
    //   }

    //   return newVal;
    // },
    dateBegin(newVal, oldVal) {
      if (this.hourBegin) this.form.date_begin = `${newVal}T${this.hourBegin}`;
    },
    dateEnd(newVal, oldVal) {
      if (this.hourEnd) this.form.date_end = `${newVal}T${this.hourEnd}`;
    },
    hourBegin(newVal, oldVal) {
      if (this.hourBegin) this.form.date_begin = `${this.dateBegin}T${newVal}`;
    },
    hourEnd(newVal, oldVal) {
      if (this.dateEnd) this.form.date_end = `${this.dateEnd}T${newVal}`;
    },
  },
  methods: {
    updateFormDepartments(department) {
      if (this.formDepartmentsTimeout) clearTimeout(this.formDepartmentsTimeout);

      this.formDepartmentsTimeout = setTimeout(() => {
        this.form.departments = [department];
      }, 10);
    },
    fetch() {
      axios().get('webchat/departments').then((response) => {
        this.loading = false;

        const departments = [];

        response.data.forEach((department) => {
          console.log('Holidays', department);

          departments.push({
            id: department.id,
            name: department.name,
          });
        });

        this.departments = departments;
      }).catch((error) => {
        this.loading = false;
        console.error('Holidays', error);
      });
    },
    error(message) {
      this.$toast.show({
        title: 'Horário',
        content: message,
        type: 'danger',
      });
      return false;
    },
    validate() {
      if (!this.form.name) return this.error('Defina o nome para a(s) data(s)');

      if (!this.form.date_begin || !this.form.date_end) return this.error('Defina as datas');

      if (!this.form.departments.length) return this.error('Selecione pelo menos um departamento');

      if (!this.form.message) return this.error('Defina a mensagem alternativa');

      return true;
    },
    save() {
      if (!this.validate()) return;

      this.loading = true;

      axios().post('webchat/holidays/create', this.form).then((response) => {
        this.loading = false;
        console.log('Holidays', response.data);
        this.$toast.show({
          title: 'Feriados',
          content: 'Feriado(s) cadastrado(s) para o(s) departamento(s)',
          type: 'success',
        });
        this.$router.push('/live/configs/holidays/');
      }).catch((error) => {
        this.loading = false;
        console.error('Holidays', error);
        this.$toast.show({
          title: 'Feriados',
          content: 'Erro ao cadastrar feriado(s)',
          type: 'danger',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
textarea.form-control {
  min-height: 180px;
}
</style>
